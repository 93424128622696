import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import onPageRender from '../hocs/onPageRender'
import Container from '../components/Container/Container'
import Slices from '../components/Slices/Slices'
import CategoryIntro from '../components/CategoryIntro/CategoryIntro'
import SubcategoryNav from '../components/SubcategoryNav/SubcategoryNav'
import Seo from '../components/Seo/Seo'

const buildSeoDescription = categories =>
  categories
    .filter(
      ({ category }) => category && category.document && category.document.data
    )
    .map(({ category }) => category.document.data.title.text)
    .join(', ')

const buildSliceBody = categories =>
  categories.reduce((acc, curr) => {
    const { category } = curr

    if (category && category.document && category.document.data) {
      const { body, subcategories } = category.document.data

      acc.push(...body)

      if (subcategories.length) {
        subcategories.forEach(({ subcategory }) => {
          if (
            subcategory &&
            subcategory.document &&
            subcategory.document.data
          ) {
            const { body: subcategoryBody } = subcategory.document.data
            acc.push(...subcategoryBody)
          }
        })
      }
    }

    return acc
  }, [])

const Shop = ({
  data: {
    page: {
      data,
      fields: { pagePath },
    },
    homepage: { data: homePageData },
    addVariantToCart,
    allShopifyProduct,
  },
}) => {
  const {
    title: { text: title },
  } = data
  const { categories } = homePageData
  const sliceBody = buildSliceBody(categories)
  const seoDescription = buildSeoDescription(categories)

  return (
    <>
      <Seo title={title} description={seoDescription} />
      <Container>
        <CategoryIntro
          title={title}
          pagePath={pagePath}
          nav={
            <SubcategoryNav
              categoryTitle={title}
              categoryPagePath={pagePath}
              subcategories={categories.map(({ category: subcategory }) => ({
                subcategory,
              }))}
            />
          }
        />
      </Container>
      <Container>
        <Slices
          body={sliceBody}
          allShopifyProduct={allShopifyProduct}
          addVariantToCart={addVariantToCart}
        />
      </Container>
    </>
  )
}

Shop.propTypes = {
  data: PropTypes.object,
  addVariantToCart: PropTypes.func.isRequired,
}

export default onPageRender(Shop)

export const query = graphql`
  query($id: String!, $lang: String!) {
    page: prismicShop(id: { eq: $id }) {
      data {
        title {
          text
        }
      }
      fields {
        pagePath
      }
    }
    homepage: prismicHomepage(lang: { eq: $lang }) {
      data {
        categories {
          category {
            document {
              ... on PrismicCategory {
                ...PrismicCategoryData
              }
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          shopifyId
          variants {
            sku
            price {
              amount
            }
            shopifyId
            availableForSale
          }
        }
      }
    }
  }
`
